// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import ahoy from "ahoy.js"

import '../images/server.svg'
import '../images/task.svg'
import '../images/everyday-design.svg'
import '../images/scrum-board-2.svg'
import '../images/fingerprint.svg'
import '../images/success.svg'
import '../images/social-update.svg'
import '../images/message-received.svg'
import '../images/my-password.svg'
import '../images/checkbox.svg'
import '../images/preview.png'
import '../images/motor-logo-3.png'

Rails.start()
Turbolinks.start()

function startVideoObserver () {
  if(!!window.IntersectionObserver){
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio === 1 && entry.target.paused) {
          entry.target.play()
        }
      })
    }, { threshold: 1 })

    document.querySelectorAll('video').forEach((video) => observer.observe(video))
  }
}

const modalHTML = `
<div class="fixed z-10 inset-0 overflow-y-auto" id="#submit_modal" role="dialog" data-role="modal">
  <div class="flex items-end justify-center min-h-screen text-center">
    <div class="inline-block align-bottom bg-white text-left overflow-hidden h-screen w-full flex items-center justify-center">
      <div class="bg-white px-4 max-w-xl w-full" style="max-width: 400px">
        <form action="/" method="post" style="padding-bottom: 200px">
            <div class="flex flex-col mb-4 w-xl">
              <label class="mb-2 text-gray-900" for="email">Email</label>
              <input autofocus class="border rounded py-1 px-3 text-grey-800 w-full" placeholder="email@example.com" type="email" name="email" id="email" required>
              <label class="my-2 text-gray-900" for="email">Company</label>
              <input class="border rounded py-1 px-3 text-grey-800 w-full" placeholder="Awesome Inc." name="company" id="company">
              <input hidden style="display: none" value="%{cta}" name="type">
              <input hidden style="display: none" value="%{package}" name="package">
            </div>
            <div class="text-center">
            <button type="submit" class="w-full rounded p-2 bg-primary text-base text-white hover:shadow">
              %{cta}
            </button>
            <button type="button" class="text-gray-500 underline text-sm" data-role="close-modal">
              Back
            </button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
`


const modalVideoHTML = `
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="modal" aria-modal="true">
  <div class="flex justify-center pt-8 px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" data-role="close-modal"></div>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-6xl sm:w-full">
      <video src="%{video}" controls autoplay width="100%"></video>
    </div>
  </div>
</div>
`

function openModal (cta = 'Submit', pkg = 'Pro') {
  let container = document.querySelector('[data-role="modal"]')

  if (!container) {
    container = document.createElement('div')
    container.setAttribute('data-role', 'modal')

    container.innerHTML = modalHTML.replaceAll('%{cta}', cta || 'Submit').replaceAll('%{package}', pkg)
  }

  container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener('click', () => closeModal())
  })

  const form = container.querySelector('form')

  form.addEventListener('submit', (e) => {
    e.preventDefault()

    fetch('/submit', {
      method : "POST",
      body: new FormData(form),
      headers:{
        'X-Original-Referer': originalReferer,
        'X-Submit-Type': cta
      }
    }).then(() => {
//       form.parentElement.innerHTML = `
//             <div class="text-center" style="padding-bottom: 200px">
//           Thank you for your request! We will contact you shortly!<br>
//             <button type="button" class="text-gray-500 underline text-sm" data-role="close-modal">
//               Back
//             </button>
//             </div>
// `

      form.parentElement.style.maxWidth = '700px'
      form.parentElement.style.textAlign = 'center'
      form.parentElement.innerHTML = `
      ${cta === 'Start Trial' ? '<p>We received your trial request! You will receive an email with trial instructions shortly</p><p>Feel free to book a demo if you have any additional questions</p>' : ''}
        <iframe src="https://calendly.com/motoradmin/30min?hide_gdpr_banner=1" style="width:100%;height:630px;"></iframe>
             <div class="text-center">
            <button type="button" class="text-gray-500 underline text-sm" data-role="close-modal">
              Close
            </button>
             </div>
      `

        container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
          e.addEventListener('click', () => closeModal())
        })
    })

  })

  document.body.appendChild(container)
}

function openVideoModal (video) {
  let container = document.querySelector('[data-role="modal"]')

  if (!container) {
    container = document.createElement('div')
    container.setAttribute('data-role', 'modal')

    container.innerHTML = modalVideoHTML.replace('%{video}', video)
  }

  container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener('click', () => closeModal())
  })

  document.body.appendChild(container)
}

function closeModal () {
  window.history.replaceState(window.history.state, "", "#");

  if (document.querySelector('[data-role="modal"] video')) {
    document.querySelector('[data-role="modal"] video').pause()
  }

  if (document.querySelector('[data-role="modal"]')) {
    document.querySelector('[data-role="modal"]').remove()
  }
}

let originalReferer

ahoy.configure({cookies: false})

window.onhashchange = function (e) {
  if (e.newURL.includes('#demo')) {
    openVideoModal('https://motor-admin.s3.amazonaws.com/demo.mp4')
  } else {
    closeModal()
  }
}

document.addEventListener('turbolinks:load', (e) => {
  originalReferer ||= document.referrer

  dataLayer.push({
    event: 'pageView',
    virtualUrl: e.data.url,
  })

  ahoy.track("pageView", { url: document.location.href, original_referer: originalReferer })

  if (process.env.NODE_ENV === 'production') {
    startVideoObserver()
  }

  document.querySelectorAll("input[readonly]").forEach((e) => {
    e.addEventListener("click", () => {
      dataLayer.push({
        event: 'deployCommand',
        virtualUrl: location.href
      })

      ahoy.track("deployCommand", {
        url: document.location.href,
        content: e.value,
        original_referer: originalReferer
      })

      e.select()
    })
  })

  document.querySelectorAll("button[data-role='open-modal']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({
        event: 'openForm',
        virtualUrl: location.href
      })

      ahoy.track("openForm", {
        type: "book-demo",
        url: document.location.href,
        original_referer: originalReferer
      })

      openModal(e.getAttribute('data-cta'))
    })
  })

  document.querySelectorAll("button[data-role='open-trial']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({
        event: 'openTrial',
        virtualUrl: location.href
      })

      ahoy.track("openForm", {
        type: "start-trial",
        url: document.location.href,
        original_referer: originalReferer
      })

      openModal(e.getAttribute('data-cta'), e.getAttribute('data-package'))
    })
  })

  document.querySelectorAll("[data-role='open-demo']").forEach((e) => {
    e.addEventListener('click', () => {
      window.history.pushState({}, "", "#demo");

      dataLayer.push({
        event: 'openVideo',
        virtualUrl: location.href
      })

      ahoy.track("openVideo", { type: "demo" })

      openVideoModal(e.getAttribute('data-src') || 'https://www.getmotoradmin.com/vids/demo.mp4')
    })
  })

  document.querySelectorAll("[data-role='heroku-deploy-btn']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({
        event: 'deployHeroku',
        virtualUrl: location.href
      })

      ahoy.track('deployHeroku', {
        url: document.location.href,
        original_referer: originalReferer
      })
    })
  })

  document.querySelectorAll("[data-role='railway-deploy-btn']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({
        event: 'deployRailway',
        virtualUrl: location.href
      })

      ahoy.track('deployRailway', {
        url: document.location.href,
        original_referer: originalReferer
      })
    })
  })

  document.querySelectorAll("[data-role='do-deploy-btn']").forEach((e) => {
    e.addEventListener('click', () => {
      dataLayer.push({
        event: 'deployDO',
        virtualUrl: location.href
      })

      ahoy.track('deployDO', {
        url: document.location.href,
        original_referer: originalReferer
      })
    })
  })

  document.querySelectorAll("[data-role='open-video']").forEach((e) => {
    e.addEventListener('click', (e) => openVideoModal(e.target.src))
  })

  document.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener('click', () => closeModal())
  })

  document.addEventListener('keydown', (e) => {
    if(e.keyCode == 27){
      closeModal()
    }
  })
})
